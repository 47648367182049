import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './coi/campingonlineicons.css'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import * as Sentry from '@sentry/vue'
import VueI18n from 'vue-i18n'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://2949bca561579b645fa232797c1a8d68@o1418389.ingest.us.sentry.io/4507260387000320',
    integrations: [
      Sentry.replayIntegration()
    ],
    replaysOnErrorSampleRate: 1.0,
    logErrors: true,
    release: process.env.VUE_APP_VERSION,
    beforeSend (event, hint) {
      const error = hint.originalException
      if (error.name === 'NavigationDuplicated' || error.message?.includes('Navigation cancelled')) {
        return null
      }
      return event
    }
  })
}

Vue.use(VueMeta)
Vue.config.productionTip = false

Vue.use(VueI18n)

let userLanguage
const acceptedLanguages = ['sv', 'en']
const storedLanguage = window.localStorage.getItem('user_language')
if (storedLanguage && acceptedLanguages.includes(storedLanguage)) {
  userLanguage = storedLanguage
} else {
  const preferredLanguage = navigator.language.substring(0, 2).toLowerCase()

  if (acceptedLanguages.includes(preferredLanguage)) {
    userLanguage = preferredLanguage
  } else {
    const browserLanguages = navigator.languages
    for (let i = 0; i < browserLanguages.length; i++) {
      const language = browserLanguages[i].substring(0, 2).toLowerCase()
      if (acceptedLanguages.includes(language)) {
        userLanguage = language
        break
      }
    }
    if (!userLanguage) { userLanguage = 'sv' }
  }

  window.localStorage.setItem('user_language', userLanguage)
}

const i18n = new VueI18n({
  locale: userLanguage,
  fallbackLocale: 'sv',
  messages: {
    sv: require('@/locales/sv.json'),
    en: require('@/locales/en.json')
  }
})
window.i18n = i18n

/* Day.js and plugins */
require('dayjs/locale/sv')
window.dayjs = require('dayjs')
window.dayjs.locale(userLanguage)
const isBetween = require('dayjs/plugin/isBetween')
window.dayjs.extend(isBetween)
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
window.dayjs.extend(isSameOrBefore)
const customParseFormat = require('dayjs/plugin/customParseFormat')
window.dayjs.extend(customParseFormat)
const arraySupport = require('dayjs/plugin/arraySupport')
window.dayjs.extend(arraySupport)

window.conversion = require('@/plugins/conversion.js').default

store.subscribe((mutation, state) => {
  /* console.log(mutation.type, mutation.payload, state); */
  if (mutation.type.toLowerCase().includes('booking')) {
    localStorage.setItem('booking', JSON.stringify(state.booking))
  }
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  beforeCreate () {
    this.$store.dispatch('initialiseStore')
  },
  created () {
    store.dispatch('refreshNow')
    setInterval(function () {
      store.dispatch('refreshNow')
    }, 1000)
  },
  render: h => h(App)
}).$mount('#app')
