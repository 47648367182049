import API from '@/services/API.js'
import router from '@/router'

export const namespaced = true

export const state = {
  availabilities: [],
  availabilityRequests: [],
  prices: [],
  priceRequests: [],
  hasFormError: false,
  positionValidation: {
    hasError: false,
    errorText: ''
  },
  fetchedDateAvailability: [],
  fetchingDateAvailability: [],
  failedDateAvailability: [],
  messages: [] // Meddelanden från servern som visas på söksida/kategorisidor
}

export const mutations = {
  SET_AVAILABILITY (state, availability) {
    state.availabilities.push(availability)
    this.dispatch('availability/validatePosition')
  },
  CLEAR_AVAILABILITY (state) {
    state.availabilities.splice(0)
  },
  SET_HAS_FORM_ERROR (state, val) {
    state.hasFormError = val
  },
  SET_PRICES (state, prices) {
    state.prices.push(prices)
  },
  SET_POSITION_VALIDATION (state, val) {
    state.positionValidation = val
  },
  ADD_FETCHING_DATE_AVAILABILITY (state, val) {
    state.fetchingDateAvailability.push(val)
  },
  REMOVE_FETCHING_DATE_AVAILABILITY (state, val) {
    state.fetchingDateAvailability.splice(state.fetchingDateAvailability.indexOf(val), 1)
  },
  ADD_FETCHED_DATE_AVAILABILITY (state, val) {
    state.fetchedDateAvailability.push(val)
  },
  ADD_FAILED_DATE_AVAILABILITY (state, val) {
    state.failedDateAvailability.unshift(val) // lägger den i början av arrayen så find alltid hittar den senaste
  },
  CLEAR_FETCHED_SECOND_DATE_AVAILABILITY (state) {
    state.fetchedDateAvailability = state.fetchedDateAvailability.filter(f => f.type != 'second')
  },
  SET_MESSAGES (state, val) {
    state.messages = val
  }
}

export const actions = {
  clearFetchedSecondDateAvailability ({ commit }) {
    commit('CLEAR_FETCHED_SECOND_DATE_AVAILABILITY')
  },
  fetchDateAvailabilityCheckIn ({ commit, state }, { year, month, categoryId }) {
    console.log('fetchDateAvailabilityCheckIn')
    if (isNaN(month) || isNaN(year)) {
      return false
    }
    const obj = {
      month,
      year,
      type: 'first',
      category_id: categoryId
    }

    if (state.fetchingDateAvailability.find(f => { return f.type == 'first' && f.year == obj.year && f.month == obj.month && f.category_id == obj.category_id }) != null) {
      return false // hämtar redan samma data
    }
    if (state.fetchedDateAvailability.find(f => { return f.type == 'first' && f.year == obj.year && f.month == obj.month && f.category_id == obj.category_id }) != null) {
      return false // redan hämtat data
    }
    const failedAttempt = (state.failedDateAvailability.find(f => { return f.type == 'first' && f.year == obj.year && f.month == obj.month && f.category_id == obj.category_id }))
    console.log('failedAttempt', failedAttempt)
    if (failedAttempt && failedAttempt.received_at.isAfter(window.dayjs().subtract(30, 'seconds'))) {
      console.log('wont try again')
      return false
    }

    commit('ADD_FETCHING_DATE_AVAILABILITY', obj)
    API.httpClient.get('booking/checkindates', {
      params: {
        year: obj.year,
        month: obj.month,
        category_id: obj.category_id
      }
    }).then(({ data }) => {
      commit('ADD_FETCHED_DATE_AVAILABILITY', {
        type: 'first',
        year: obj.year,
        month: obj.month,
        dates: data,
        category_id: obj.category_id
      })
    }).catch((error) => {
      console.log('checkindates error response:', error, error.response)
      obj.received_at = window.dayjs()
      commit('ADD_FAILED_DATE_AVAILABILITY', obj)
      // TODO: visa felmeddelande ist för månadskalendern med retry knapp?
    })
      .finally(() => {
        commit('REMOVE_FETCHING_DATE_AVAILABILITY', obj)
      })
  },
  fetchDateAvailabilityCheckOut ({ commit, state }, { year, month, categoryId, firstDate }) {
    console.log('fetchDateAvailabilityCheckOut')
    if (isNaN(month) || isNaN(year)) {
      return false
    }
    const obj = {
      month,
      year,
      type: 'second',
      date: firstDate,
      category_id: categoryId
    }

    if (state.fetchingDateAvailability.find(f => { return f.type == 'second' && f.year == obj.year && f.month == obj.month && f.date == obj.date && f.category_id == obj.category_id }) != null) {
      return false // hämtar redan samma data
    }
    if (state.fetchedDateAvailability.find(f => { return f.type == 'second' && f.year == obj.year && f.month == obj.month && f.date == obj.date && f.category_id == obj.category_id }) != null) {
      // TODO: kontrollera när hämtad och hämta igen om >10 min
      return false // redan hämtat data
    }
    const failedAttempt = (state.failedDateAvailability.find(f => { return f.type == 'second' && f.year == obj.year && f.month == obj.month && f.date == obj.date && f.category_id == obj.category_id }))
    console.log('failedAttempt', failedAttempt)
    if (failedAttempt && failedAttempt.received_at.isAfter(window.dayjs().subtract(30, 'seconds'))) {
      console.log('wont try again')
      return false
    }

    commit('ADD_FETCHING_DATE_AVAILABILITY', obj)
    API.httpClient.get('booking/checkoutdates', {
      params: {
        date: obj.date,
        year: obj.year,
        month: obj.month,
        category_id: obj.category_id
      }
    }).then(({ data }) => {
      if (firstDate != obj.date) {
        console.log('firstDate HAR ÄNDRATS', firstDate, obj.date)
        // firstDate HAR ÄNDRATS
      } else {
        commit('ADD_FETCHED_DATE_AVAILABILITY', {
          type: 'second',
          year: obj.year,
          month: obj.month,
          date: obj.date,
          dates: data,
          category_id: obj.category_id
        })
        /* this.checkBookability(); */ // TODO: ??
      }
    }).catch(error => {
      console.log('checkoutdates error response:', error, error.response)
      obj.received_at = window.dayjs()
      commit('ADD_FAILED_DATE_AVAILABILITY', obj)
      // TODO: visa felmeddelande ist för månadskalendern med retry knapp?
    })
      .finally(() => {
        commit('REMOVE_FETCHING_DATE_AVAILABILITY', obj)
      })
  },
  fetchAvailability ({ commit, state, rootState }, force) {
    const context = router.currentRoute.name
    let checkIn; let checkOut; let categoryId; let guestQuantity; let dogsQuantity
    if (force) {
      commit('CLEAR_AVAILABILITY')
    }
    if (context == 'SearchPage' || context == 'Index') {
      checkIn = rootState.search.checkInDate.param
      checkOut = rootState.search.checkOutDate.param
      categoryId = null
      guestQuantity = rootState.search.adults + rootState.search.children
      dogsQuantity = rootState.search.dogs
      if (rootState.search.dateValidation.hasError) {
        commit('SET_HAS_FORM_ERROR', true)
        return false
      } else {
        commit('SET_HAS_FORM_ERROR', false)
      }
    } else if (context == 'CategoryPage') {
      checkIn = rootState.booking.checkInDate.param
      checkOut = rootState.booking.checkOutDate.param
      guestQuantity = rootState.booking.adults + rootState.booking.children
      dogsQuantity = rootState.booking.dogs
      categoryId = rootState.currentCategory ? rootState.currentCategory.id : null
    }

    const params = {
      check_in: checkIn,
      check_out: checkOut,
      session: rootState.booking.reservation.session,
      category_id: categoryId,
      guest_quantity: guestQuantity,
      dogs_quantity: dogsQuantity
    }

    if (!params.check_in || !params.check_out) {
      return false
    }

    if (state.availabilityRequests.filter(a => a.check_in == checkIn && a.check_out == checkOut && a.category_id == categoryId && a.guest_quantity === guestQuantity && a.dogs_quantity === dogsQuantity).length > 0) {
      return false
    }
    if (state.availabilities.filter(a => a.params.check_in == checkIn && a.params.check_out == checkOut && a.params.category_id == categoryId && a.params.guest_quantity === guestQuantity && a.params.dogs_quantity === dogsQuantity && a.fetchedAt.isAfter(window.dayjs().subtract(5, 'minutes'))).length > 0) {
      this.dispatch('availability/validatePosition')
      return false
    }

    state.availabilityRequests.push(params)
    API.httpClient.get('booking/availability', {
      params
    }).then(({ data }) => {
      commit('SET_AVAILABILITY', { data: data.availability, params, fetchedAt: window.dayjs() })
      commit('SET_MESSAGES', data.messages)
    }).catch((error) => {
      console.error('fetchAvailability error response:', error, error.response)
    }).finally(() => {
      state.availabilityRequests = state.availabilityRequests.filter(r => r != params)
    })
  },
  fetchPrices ({ commit, state, rootState }) {
    const checkIn = rootState.search.checkInDate.param
    const checkOut = rootState.search.checkOutDate.param
    const guestQuantity = rootState.search.adults + rootState.search.children
    const dogsQuantity = rootState.search.dogs

    if (rootState.search.dateValidation.hasError) {
      commit('SET_HAS_FORM_ERROR', true)
      return false
    } else {
      commit('SET_HAS_FORM_ERROR', false)
    }

    const params = {
      check_in: checkIn,
      check_out: checkOut,
      guest_quantity: guestQuantity,
      dogs_quantity: dogsQuantity
    }

    if (!params.check_in || !params.check_out) {
      return false
    }
    if (state.priceRequests.filter(a => a.check_in == checkIn && a.check_out == checkOut && a.guest_quantity == guestQuantity && a.dogs_quantity === dogsQuantity).length > 0) {
      return false
    }
    if (state.prices.filter(a => a.params.check_in == checkIn && a.params.check_out == checkOut && a.params.guest_quantity == guestQuantity && a.params.dogs_quantity === dogsQuantity && a.fetchedAt.isAfter(window.dayjs().subtract(60, 'minutes'))).length > 0) {
      return false
    }

    state.priceRequests.push(params)
    API.httpClient.get('booking/averagecharges', {
      params
    })
      .then(({ data }) => {
        commit('SET_PRICES', { data, params, fetchedAt: window.dayjs() })
      })
      .catch((error) => {
        console.log('prices error response:', error, error.response)
      })
      .finally(() => {
        state.priceRequests = state.priceRequests.filter(r => r != params)
      })
  },
  validatePosition ({ state, rootState, commit }) {
    if (!rootState.booking.position) {
      commit('SET_POSITION_VALIDATION', {
        hasError: true,
        errorText: 'Välj en plats'
      })
    } else {
      const checkIn = rootState.booking.checkInDate.param
      const checkOut = rootState.booking.checkOutDate.param
      const categoryId = rootState.booking.position.category_id
      const positionId = rootState.booking.position.id
      const guestQuantity = rootState.booking.adults + rootState.booking.children
      const dogsQuantity = rootState.booking.dogs

      const availability = state.availabilities.find(a => a.params.check_in == checkIn && a.params.check_out == checkOut && a.params.category_id == categoryId && a.params.guest_quantity == guestQuantity && a.params.dogs_quantity == dogsQuantity)
      let positionStatus
      if (!availability) {
        commit('SET_POSITION_VALIDATION', {
          hasError: false,
          errorText: ''
        })
        return false
      }
      if (rootState.booking.position.id != 0) {
        positionStatus = availability.data[categoryId].positions[positionId].position_status
      } else {
        positionStatus = availability.data[categoryId].is_available ? '' : availability.data[categoryId].displayed_text
        /* positionStatus = Object.values(availability.data[categoryId].positions).filter(p => {return p.position_status == "available"}).length > 0 ? "" : "full"; */
      }

      if (positionStatus == '' || positionStatus == 'available') {
        commit('SET_POSITION_VALIDATION', {
          hasError: false,
          errorText: ''
        })
      } else if (positionStatus == 'reserved') {
        commit('SET_POSITION_VALIDATION', {
          hasError: true,
          errorText: this.$t('availability.position_not_available')
        })
      } else if (positionStatus == 'unreservable') {
        commit('SET_POSITION_VALIDATION', {
          hasError: true,
          errorText: this.$t('availability.position_not_bookable')
        })
      } else if (positionStatus == 'full') {
        commit('SET_POSITION_VALIDATION', {
          hasError: true,
          errorText: this.$t('availability.no_available_positions')
        })
      } else {
        commit('SET_POSITION_VALIDATION', {
          hasError: true,
          errorText: positionStatus
        })
      }
    }
  }
}

export const getters = {
  availabilitySearchPage: (state, getters, rootState) => {
    const checkIn = rootState.search.checkInDate.param
    const checkOut = rootState.search.checkOutDate.param
    const guestQuantity = rootState.search.adults + rootState.search.children
    const dogsQuantity = rootState.search.dogs
    const categoryId = null
    const availability = state.availabilities.find(a => a.params.check_in == checkIn && a.params.check_out == checkOut && a.params.category_id == categoryId && a.params.guest_quantity == guestQuantity && a.params.dogs_quantity == dogsQuantity)
    if (availability) {
      return availability.data
    }
    return []
  },
  pricesSearchPage: (state, getters, rootState) => {
    const checkIn = rootState.search.checkInDate.param
    const checkOut = rootState.search.checkOutDate.param
    const guestQuantity = rootState.search.adults + rootState.search.children
    const dogsQuantity = rootState.search.dogs
    const prices = state.prices.find(a => a.params.check_in == checkIn && a.params.check_out == checkOut && a.params.guest_quantity == guestQuantity && a.params.dogs_quantity == dogsQuantity)
    if (prices) {
      return prices.data
    }
    return []
  },
  availabilityCategoryPage: (state, getters, rootState) => {
    const checkIn = rootState.booking.checkInDate.param
    const checkOut = rootState.booking.checkOutDate.param
    const categoryId = rootState.currentCategory.id
    const guestQuantity = rootState.booking.adults + rootState.booking.children
    const dogsQuantity = rootState.booking.dogs

    const availability = state.availabilities.find(a => a.params.check_in == checkIn && a.params.check_out == checkOut && (a.params.category_id == null || a.params.category_id == categoryId) && a.params.guest_quantity == guestQuantity && a.params.dogs_quantity == dogsQuantity)
    if (availability) {
      return availability.data[categoryId]
    }
    return {}
  },
  displayedMessages (state, getters, rootState) {
    if (rootState.currentPageName === 'Index' || rootState.currentPageName === 'SearchPage') {
      return state.messages.filter(msg => msg.show_on_search_page === 1)
    } else if (rootState.currentPageName === 'CategoryPage') {
      return state.messages.filter(msg => msg.show_on_category_ids.includes(rootState.currentCategory.id))
    }
    return []
  }
}
