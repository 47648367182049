import API from '@/services/API.js'
import router from '@/router'
import Vue from 'vue'

export const namespaced = true

export const state = {
  isInitialised: false,
  draft: {
    category: null, // OBS: detta är inte hela category objekt, objekt från webApiController->selfCheckinCategories
    categoryProper: null, // Detta är hela category objektet (från web, inte server)
    position: null,
    nights: 1,
    adults: 2,
    children: 0,
    dogs: 0,
    electricitySelected: false
  },
  isFetchingCharges: false,
  categories: null, // OBS: detta är inte hela category objekt, objekt från webApiController->selfCheckinCategories
  availability: null,
  charges: [],
  reservation: { // Ev. ändringar ska också göras i REMOVE_RESERVATION mutation
    session: null,
    request: {
      isProcessing: false,
      check_in: null,
      check_out: null,
      position_id: null,
      category_id: null,
      occupancy: 0,
      dogs: 0,
      is_self_checkin_booking: true
    },
    hold: null,
    is_available: false,
    receivedAt: null
  },
  isSavingBooking: false,
  saveBookingErrorMessage: '',
  confirmedBooking: null,
  bookingInformation: null,
  isFetchingBookingInformation: false,
  adultsChangedMessage: ''
}

export const mutations = {
  SET_IS_INITIALISED (state, val) {
    state.isInitialised = val
  },
  SET_CATEGORIES (state, val) {
    state.categories = val
  },
  SET_CATEGORY_MAX_NIGHTS (state, val) {
    const categoryIndex = state.categories.findIndex(category => category.id === val.id)
    state.categories[categoryIndex].max_available_nights = val.max_available_nights
    if (state.draft.category?.id === val.id && state.draft.nights > val.max_available_nights && val.max_available_nights !== 0) {
      state.draft.nights = val.max_available_nights
      localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
    }
  },
  SET_AVAILABILITY (state, val) {
    state.availability = val
  },
  SET_DRAFT (state, val) {
    state.draft = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_DRAFT_ADULTS (state, val) {
    state.draft.adults = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_DRAFT_CHILDREN (state, val) {
    state.draft.children = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_DRAFT_DOGS (state, val) {
    state.draft.dogs = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_DRAFT_CATEGORY (state, val) {
    state.draft.category = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_DRAFT_CATEGORY_PROPER (state, val) {
    state.draft.categoryProper = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_DRAFT_POSITION (state, val) {
    state.draft.position = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_DRAFT_NIGHTS (state, val) {
    state.draft.nights = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_DRAFT_ELECTRICITY_SELECTED (state, val) {
    state.draft.electricitySelected = val
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_IS_FETCHING_CHARGES (state, val) {
    state.isFetchingCharges = val
  },
  SET_CHARGES (state, val) {
    state.charges = val
  },
  SET_RESERVATION (state, data) {
    if (data.hold && data.hold.release_time) {
      data.hold.release_time = window.dayjs(data.hold.release_time)
    }
    state.reservation = data
    localStorage.setItem('checkin_reservation', JSON.stringify(state.reservation))

    if (state.reservation.hold?.position_id && state.draft.position_id !== state.reservation.hold.position_id) {
      state.draft.position_id = state.reservation.hold.position_id
      localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
    }
  },
  REMOVE_RESERVATION (state) {
    state.reservation = {
      session: null,
      request: {
        isProcessing: false,
        check_in: null,
        check_out: null,
        position_id: null,
        category_id: null,
        occupancy: 0,
        dogs: 0,
        is_self_checkin_booking: true
      },
      hold: null,
      is_available: false,
      receivedAt: null
    }
    localStorage.setItem('checkin_reservation', JSON.stringify(state.reservation))
  },
  RESET_DRAFT (state) {
    state.draft.category = null
    state.draft.categoryProper = null
    state.draft.position = null
    state.draft.nights = 1
    state.draft.adults = 2
    state.draft.children = 0
    state.draft.dogs = 0
    state.draft.electricitySelected = false
    localStorage.setItem('checkin_draft', JSON.stringify(state.draft))
  },
  SET_RESERVATION_IS_PROCESSING (state, isProcessing) {
    state.reservation.request.isProcessing = isProcessing
  },
  SET_CONFIRMED_BOOKING (state, val) {
    state.confirmedBooking = val
    localStorage.setItem('checkin_confirmed_booking', JSON.stringify(state.confirmedBooking))
  },
  SET_IS_SAVING_BOOKING (state, val) {
    state.isSavingBooking = val
  },
  SET_SAVE_BOOKING_ERROR_MESSAGE (state, val) {
    state.saveBookingErrorMessage = val
  },
  SET_BOOKING_INFORMATION (state, val) {
    state.bookingInformation = val
  },
  SET_IS_FETCHING_BOOKING_INFORMATION (state, val) {
    state.isFetchingBookingInformation = val
  },
  SET_CONFIRMED_BOOKING_PAYMENT_INTENT (state, val) {
    if (state.confirmedBooking) {
      Vue.set(state.confirmedBooking, 'paymentIntent', val)
    }
  },
  SET_ADULTS_CHANGED_MESSAGE (state, val) {
    state.adultsChangedMessage = val
  }
}

export const actions = {
  initialise ({ state, commit, dispatch }, { force }) {
    commit('SET_IS_INITIALISED', false)
    if (state.isInitialised && !force) {
      return false
    }
    Promise.allSettled([
      dispatch('fetchCategories'),
      dispatch('getDraftFromStorage'),
      dispatch('getReservationFromStorage'),
      dispatch('getConfirmedBookingFromStorage')
    ])
      .then(() => {
        const requiresDraft = ['CheckinSelectNights', 'CheckinMap']
        const requiresReservation = ['CheckinGuestDetails', 'CheckinPaymentPage']
        // TODO: resetCheckin om det är en gammal draft
        if (!state.draft.category && requiresDraft.includes(router.currentRoute.name)) {
          dispatch('resetCheckin')
        } else if (!state.reservation.hold && requiresReservation.includes(router.currentRoute.name)) {
          dispatch('resetCheckin')
        } else {
          dispatch('fetchCharges')
        }
        commit('SET_IS_INITIALISED', true)
      })
  },
  getDraftFromStorage ({ commit }) {
    return new Promise((resolve) => {
      let localDraft = localStorage.getItem('checkin_draft')
      localDraft = JSON.parse(localDraft)
      if (!localDraft || typeof localDraft !== 'object') {
        localStorage.removeItem('checkin_draft')
        resolve(false)
        return false
      }
      commit('SET_DRAFT', localDraft)
      resolve(true)
    })
  },
  getConfirmedBookingFromStorage ({ commit }) {
    return new Promise((resolve) => {
      let localConfirmedBooking = localStorage.getItem('checkin_confirmed_booking')
      localConfirmedBooking = JSON.parse(localConfirmedBooking)

      if (!localConfirmedBooking || typeof localConfirmedBooking !== 'object') {
        localStorage.removeItem('checkin_confirmed_booking')
        resolve(false)
        return false
      }

      if (localConfirmedBooking.created_at) {
        localConfirmedBooking.check_out = window.dayjs(localConfirmedBooking.check_out)
        if (localConfirmedBooking.check_out.isAfter(window.dayjs().subtract(1, 'day'))) {
          commit('SET_CONFIRMED_BOOKING', localConfirmedBooking)
        }
      }
      resolve(true)
    })
  },
  getReservationFromStorage ({ commit }) {
    return new Promise((resolve) => {
      let localReservation = localStorage.getItem('checkin_reservation')
      localReservation = JSON.parse(localReservation)
      if (!localReservation || typeof localReservation !== 'object') {
        localStorage.removeItem('checkin_reservation')
        resolve(false)
        return false
      }
      commit('SET_RESERVATION', localReservation)
      resolve(true)
    })
  },
  selectCategory ({ rootState, commit, dispatch }, { category }) {
    window.umami.track(process.env.VUE_APP_CAMPING + '_checkin_category_selected')
    const hasCategoryChanged = state.draft.category?.id !== category?.id
    if (hasCategoryChanged) {
      commit('SET_DRAFT_POSITION', null)
    }
    const categoryProper = rootState.categories.find(cat => cat.id === category.id)
    commit('SET_DRAFT_CATEGORY', category)
    commit('SET_DRAFT_CATEGORY_PROPER', categoryProper)
    router.push({ name: 'CheckinSelectNights' })
  },
  selectRandomPosition ({ state, commit }) {
    window.umami.track(process.env.VUE_APP_CAMPING + '_random_position_selected')
    return new Promise((resolve) => {
      if (!state.availability) {
        resolve(false)
        return false
      }

      const categoryPositions = state.draft.categoryProper.positions
      const availablePositions = categoryPositions.filter(position => {
        // Filtrerar också bort nuvarande vald plats, så den alltid väljer en ny plats
        return state.availability.positions[position.id]?.position_status === 'available' && state.draft.position?.id !== position.id
      })

      if (availablePositions.length > 0) {
        const randomPosition = availablePositions[Math.floor((Math.random() * availablePositions.length))]
        commit('SET_DRAFT_POSITION', randomPosition ?? null)
      }

      resolve(true)
    })
  },
  nightsSelected ({ state, dispatch }) {
    window.umami.track(process.env.VUE_APP_CAMPING + '_checkin_nights_selected')
    const isSelectablePositions = state.draft.categoryProper.selectable
    if (isSelectablePositions) {
      dispatch('goToMapPage')
    } else {
      dispatch('positionSelected')
    }
  },
  positionSelected ({ commit, dispatch, state }) {
    window.umami.track(process.env.VUE_APP_CAMPING + '_position_selected')
    // Släpper igenom utan lokal availability validering när server tilldelar plats
    if (!state.draft.position || !state.availability || state.availability.positions[state.draft.position.id]?.position_status === 'available') {
      dispatch('reservePosition')
    } else {
      commit('SET_DRAFT_POSITION', null)
    }
  },
  goToMapPage ({ dispatch }) {
    dispatch('fetchAvailability')
    router.push({ name: 'CheckinMap' })
  },
  goToPaymentPage () {
    router.push({ name: 'CheckinPaymentPage' })
  },
  preparePaymentIntent ({ state, dispatch }) {
    const session = state.reservation.session
    const amount = state.charges.reduce((acc, charge) => acc + charge.quantity * charge.price, 0) * 100
    if (amount !== 0) { dispatch('checkout/createPaymentIntent', { session, amount }, { root: true }) }
  },
  fetchCategories ({ commit }) {
    return API.httpClient.get('booking/self-checkin-categories')
      .then(({ data }) => {
        commit('SET_CATEGORIES', data.categories)
      })
  },
  fetchAvailability ({ state, commit }) {
    API.httpClient.get('booking/self-checkin-availability', {
      params: {
        category_id: state.draft.category.id,
        nights: state.draft.nights,
        adults: state.draft.adults,
        children: state.draft.children,
        dogs: state.draft.dogs
      }
    })
      .then(({ data }) => {
        commit('SET_AVAILABILITY', data.availability)
      })
  },
  fetchCharges ({ state, commit }) {
    if (!state.draft.category) {
      return false
    }
    const nights = state.draft.nights
    const checkIn = window.dayjs().startOf('day')
    const checkOut = window.dayjs().startOf('day').add(nights, 'days')
    const categoryId = state.draft.category.id
    const guestQuantity = state.draft.adults + state.draft.children
    const dogsQuantity = state.draft.dogs
    const electricitySelected = state.draft.electricitySelected

    commit('SET_CHARGES', [])
    commit('SET_IS_FETCHING_CHARGES', true)
    API.httpClient.get('booking/positioncharges', {
      params: {
        check_in: checkIn.format('YYYY-MM-DD'),
        check_out: checkOut.format('YYYY-MM-DD'),
        category_id: categoryId,
        guest_quantity: guestQuantity,
        dogs_quantity: dogsQuantity,
        electricity_selected: electricitySelected
      }
    })
      .then(({ data }) => {
        if (state.draft.nights === nights && state.draft.category.id === categoryId && (state.draft.adults + state.draft.children) === guestQuantity && state.draft.dogs === dogsQuantity && state.draft.electricitySelected === electricitySelected) {
          commit('SET_CHARGES', data.charges)
        }
      })
      .catch((error) => {
        console.log('charges error response:', error, error.response)
      })
      .finally(() => {
        if (state.draft.nights === nights && state.draft.category.id === categoryId && (state.draft.adults + state.draft.children) === guestQuantity && state.draft.dogs === dogsQuantity && state.draft.electricitySelected === electricitySelected) {
          commit('SET_IS_FETCHING_CHARGES', false)
        }
      })
  },
  updateCategoryMaxNights ({ state, commit }) {
    API.httpClient.get('booking/self-checkin-max-nights', {
      params: {
        category_id: state.draft.category.id,
        adults: state.draft.adults,
        children: state.draft.children,
        dogs: state.draft.dogs
      }
    })
      .then(({ data }) => {
        commit('SET_CATEGORY_MAX_NIGHTS', data.category_max_nights) // data.category_max_nights är ett objekt med attributen id och max_available_nights
      })
  },
  reservePosition ({ commit, dispatch, state }) {
    const checkIn = window.dayjs().startOf('day')
    const checkOut = window.dayjs().startOf('day').add(state.draft.nights, 'days')
    if (checkIn.isSame(checkOut)) {
      return false
    }
    state.reservation.request.isProcessing = true
    state.reservation.request.check_in = checkIn.format('YYYY-MM-DD')
    state.reservation.request.check_out = checkOut.format('YYYY-MM-DD')
    state.reservation.request.position_id = state.draft.position?.id ?? 0
    state.reservation.request.category_id = state.draft.category.id
    state.reservation.request.occupancy = state.draft.adults + state.draft.children
    state.reservation.request.dogs = state.draft.dogs
    state.reservation.request.is_self_checkin_booking = true
    return API.httpClient.get('booking/hold', {
      params: {
        check_in: state.reservation.request.check_in,
        check_out: state.reservation.request.check_out,
        position_id: state.reservation.request.position_id,
        category_id: state.reservation.request.category_id,
        session: state.reservation.session,
        occupancy: state.reservation.request.occupancy,
        dogs: state.reservation.request.dogs,
        is_self_checkin_booking: state.reservation.request.is_self_checkin_booking
      }
    })
      .then(({ data }) => {
        if (
          window.dayjs(data.hold.check_in).isSame(checkIn) &&
          window.dayjs(data.hold.check_out).isSame(checkOut) &&
          (!state.draft.position || parseInt(data.hold.position_id, 10) === state.draft.position.id) &&
          parseInt(data.hold.category_id, 10) === state.draft.category.id
        ) {
          commit('SET_RESERVATION', {
            is_available: data.is_available,
            session: data.session,
            request: state.reservation.request,
            hold: data.hold,
            receivedAt: window.dayjs()
          })
          if (data.is_available) {
            const categoryPositions = state.draft.categoryProper.positions
            const selectedPosition = categoryPositions.find(pos => pos.id === data.hold.position_id)
            commit('SET_DRAFT_POSITION', selectedPosition)
            router.push({ name: 'CheckinGuestDetails' })
          } else {
            commit('SET_SAVE_BOOKING_ERROR_MESSAGE', this.$t('nightspicker.position_no_longer_available'))
            dispatch('fetchAvailability')
          }
        }
      })
      .catch((error) => {
        dispatch('resetCheckin')
        console.log('reservePosition error response:', error, error.response)
      })
      .finally(() => {
        commit('SET_RESERVATION_IS_PROCESSING', false)
      })
  },
  saveBooking ({ state, dispatch, commit }) {
    return new Promise((resolve, reject) => {
      if (state.reservation?.hold?.booking_number === state.confirmedBooking?.booking_number && state.confirmedBooking?.booking_number != null) {
        resolve()
        return
      }

      API.httpClient.post('booking/create', {
        session: state.reservation.session,
        adults: state.draft.adults,
        children: state.draft.children,
        dogs: state.draft.dogs,
        payment_method: 'stripe', // alltid stripe
        cancellation_policy: null, // alltid null här (server väljer, borde förbättras i server)
        charges: state.charges,
        position_id: state.draft.position.id,
        category_id: state.draft.category.id,
        selected_addon_options: null,
        await_confirmation: true,
        electricity_selected: state.draft.electricitySelected
      })
        .then(({ data }) => {
          if (data.status === 'success') {
            commit('SET_CONFIRMED_BOOKING', data.booking)
            resolve()
          } else {
            commit('SET_SAVE_BOOKING_ERROR_MESSAGE', 'Kunde inte bekräfta bokningen')
            setTimeout(() => {
              dispatch('resetCheckin')
            }, 5000)
            reject(new Error(data.status))
          }
        })
        .catch((error) => {
          console.log('saveBooking error', error)
          commit('SET_SAVE_BOOKING_ERROR_MESSAGE', 'Kunde inte bekräfta bokningen')
          commit('SET_IS_SAVING_BOOKING', false)
          setTimeout(() => {
            dispatch('resetCheckin')
          }, 5000)
          reject(new Error(error?.response?.status))
        })
    })
  },
  resetCheckin ({ commit }) {
    commit('RESET_DRAFT')
    commit('REMOVE_RESERVATION')
    commit('SET_CHARGES', [])
    commit('SET_CONFIRMED_BOOKING', null)
    commit('SET_BOOKING_INFORMATION', null)
    router.push({ name: 'CheckinIndexPage' })
  },
  getBookingInformation ({ commit, dispatch, state }) {
    const confirmedBookingSession = state.confirmedBooking.session_key
    commit('SET_IS_FETCHING_BOOKING_INFORMATION', true)
    return API.httpClient.get('booking/get-booking-information', {
      params: {
        session: confirmedBookingSession
      }
    })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_BOOKING_INFORMATION', data)
        } else {
          // TODO: kan skicka tillbaka till betalningssidan med felmeddelande om misslyckad betalning?
          // Kan vara utgången/borttagen draft också eller att det är en gammal bokning och sidan laddats om långt senare
          // Men bättre användarfeedback behövs nog ändå här
          dispatch('resetCheckin')
        }
      })
      .catch((error) => {
        console.log('getBookingInformation error:', error)
        dispatch('resetCheckin')
        return null
      })
      .finally(() => {
        commit('SET_IS_FETCHING_BOOKING_INFORMATION', false)
      })
  }
}

export const getters = {
  formattedCharges (state) {
    const arr = []
    state.charges.forEach(charge => {
      const c = charge
      if (!c.textLeft) {
        c.textLeft = c.name
        if (c.unit && c.unit !== '') {
          c.textLeft += `, ${c.quantity} ${c.unit}`
        }
      }
      if (!c.textRight) {
        c.textRight = ''
        if (c.price || c.price === 0) {
          c.textRight += Math.round(c.quantity * c.price)
          if (c.currency) {
            c.textRight += ' ' + c.currency
          }
        }
      }
      arr.push(c)
    })
    return arr
  },
  totalAmount (state) {
    return state.charges.reduce((acc, charge) => acc + charge.quantity * charge.price, 0) * 100
  }
}
